<template>
  <v-container>
    <v-card>
      <v-btn
        color="success"
        fab
        small
        absolute
        top
        right
        :to="{
          name: 'InventoryCompanyEdit',
          params: { id: 0 },
        }"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :search="search"
        :item-class="() => 'clickable'"
        @click:row="(item) => showCompany(item.id)"
      >
        <template v-slot:item.email="{ item }">
          <v-chip
            dark
            small
            label
            v-if="item.emailContact"
            :href="'mailto:' + item.emailContact"
            @click.stop
          >
            {{ item.emailContact }}
          </v-chip>
          <v-chip
            dark
            small
            label
            v-if="item.emailCompany"
            :href="'mailto:' + item.emailCompany"
            @click.stop
          >
            {{ item.emailCompany }}
          </v-chip>
        </template>
        <template v-slot:item.homepage="{ item }">
          <v-chip
            dark
            small
            label
            v-if="item.homepage"
            :href="ensureLink(item.homepage)"
            target="_blank"
            @click.stop
          >
            {{ item.homepage }}
          </v-chip>
        </template>
        <template v-slot:item.id="{ item }">
          <small>{{ item.id }}</small>
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description }}, {{ item.town }}
        </template>
      </v-data-table>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  name: 'InventoryCompanies',
  props: ['search'],
  data: () => ({
    loading: false,
    items: [],
    headers: [
      { text: 'Name', value: 'companyName' },
      { text: 'E-Mail', value: 'email', sortable: false },
      { text: 'WWW', value: 'homepage', sortable: false },
    ],
  }),
  methods: {
    showCompany(id) {
      this.$router.push({
        name: 'InventoryCompany',
        params: { id: id },
      });
    },
    ensureLink(link) {
      if (link.startsWith('http')) {
        return link;
      }
      return 'https://' + link;
    },
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: 'inventory/company' });
      this.loading = false;
    },
  },
  async created() {
    this.getData();
  },
};
</script>
